<template>
  <div class="list" v-if="detail">
    <div class="head">
      <img src="../assets/error-icon.png" alt="" />
      <p>支付失败!</p>
    </div>
    <div class="msg">
      <div class="name">{{ productName }}</div>
      <div class="line"></div>
      <div class="list">
        <h3>保障详情</h3>
        <div class="item">
          <p>预防接种异常反应意外身故保险金</p>
          <p>{{ detail.RBYWBXJ }}</p>
        </div>
        <div class="item">
          <p>预防接种异常反应意外伤残保险金</p>
          <p>{{ detail.RBSCBXJ }}</p>
        </div>
        <div class="item">
          <p>预防接种异常反应意外医疗费用保险金</p>
          <p>{{ detail.RBYWYLFBXJ }}</p>
        </div>
        <div class="item">
          <p>预防接种异常反应住院津贴保险金</p>
          <p>{{ detail.RBZYJTREBZ }}</p>
        </div>
        <div class="item">
          <p>生效日期</p>
          <p>{{ detail.activeDate }}</p>
        </div>
        <div class="item">
          <p>终止日期</p>
          <p>{{ detail.guaranteeDate }}</p>
        </div>
        <div class="item">
          <p>投保人</p>
          <p>{{ detail.applicantName }}</p>
        </div>
        <div class="item">
          <p>被保人</p>
          <p>{{ detail.insuredName }}</p>
        </div>
      </div>
    </div>
    <div class="file">
      <div @click="checkFile = !checkFile">
        <img v-if="checkFile" src="../assets/file-check-icon.png" alt="" />
        <img v-else src="../assets/file-icon.png" alt="" />
      </div>

      <p>我已阅读</p>
      <div class="filelist">
        <p @click="insuranceNotice">投保须知</p>
        <p @click="fileList('productTerms')">保险条款</p>
        <p @click="fileList('rateTable')">费率表</p>
        <p @click="customerNotification">客户告知</p>
      </div>
    </div>
    <div class="pay" @click="payUrl">重新支付 ￥{{ detail.prem }}</div>
    <div class="write" @click="rewriting">重新填写信息</div>
  </div>
</template>

<script>
import { rbInformation, payOrderWxPub } from "../utils/api";
export default {
  components: {},
  data() {
    return {
      checkFile: false,
      detail: null,
      productName: sessionStorage.getItem("productName"),
    };
  },
  created() {
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    this.rbInformation();
    this.$toast.clear();
  },
  methods: {
    //重新支付
    async payUrl() {
      if (this.checkFile) {
        let params = {
          openId: sessionStorage.getItem("openId"),
          channelId: "wx150dd25745296b4e",
          orderNo: sessionStorage.getItem("orderNo"),
        };
        await payOrderWxPub(params).then((res) => {
          if (res.success) {
            window.location.href = res.result;
          }
        });
      } else {
        this.$toast("请先阅读相关文件");
      }
    },
    //跳转到填写信息页面
    rewriting() {
      this.$router.push({
        path: "/insurePackage",
      });
    },
    //获取出单信息
    async rbInformation() {
      let params = {
        orderNo: sessionStorage.getItem("orderNo"),
      };
      await rbInformation(params).then((res) => {
        if (res.success) {
          this.detail = res.result;
        }
      });
    },
    //跳转投保须知
    insuranceNotice() {
      this.$router.push({
        path: "/insuranceNotice",
      });
    },
    //费率表、保险条款
    fileList(type) {
      this.$router.push({
        path: "/fileList",
        query: {
          type: type,
        },
      });
    },

    //客户告知书
    customerNotification() {
      this.$router.push({
        path: "/pdf",
        query: {
          type: "native",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.write {
  font-size: 14px;
  font-weight: 400;
  color: #bdbdbd;
  line-height: 20px;
  margin-top: 12px;
  padding-bottom: 30px;
  text-align: center;
}
.pay {
  width: 250px;
  height: 45px;
  margin: 0 auto;
  background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  line-height: 21px;
  margin-top: 16px;
}
.file {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  img {
    width: 14px;
    margin-right: 6px;
  }
  p {
    font-size: 13px;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
  }
  .filelist {
    display: flex;
    align-items: center;
    p {
      font-size: 13px;
      font-weight: 400;
      color: #569cff;
      line-height: 13px;
      padding: 0 10px;
      border-right: 1px solid #e5e5e5;
    }
    p:last-child {
      border-right: none;
    }
  }
}
.msg {
  width: 350px;
  height: 438px;
  margin: 0 auto;
  background: url("../assets/success-bg.png") no-repeat;
  background-size: 100%;
  margin-top: -64px;
  .name {
    width: 220px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 37px;
  }
  .line {
    width: 89%;
    margin: 0 auto;
    height: 2px;
    border-bottom: 2.5px dashed #ededed;
    margin-top: 21px;
  }
  .list {
    padding: 0 30px;
    h3 {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
      margin-top: 19px;
      margin-bottom: 12px;
    }
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      font-weight: 400;
      color: #666666;
      line-height: 16px;
      margin-bottom: 17px;
    }
    .item:last-child {
      margin-bottom: 0;
    }
  }
}
.head {
  width: 100%;
  height: 183px;
  background: $primary-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 48px;
    margin-top: 16px;
  }
  p {
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    margin-top: 16px;
  }
}
</style>